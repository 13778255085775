// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  bulk_connectParamsList () {
    return {'old': [ ], 'new': [ 'mdl_bldg', 'mdl_room', 'name_stop', 'name_start', 'mdl_fq_name', 'include_all_ports', 'type_group_is_fix', 'connected_mdl_bldg', 'connected_mdl_room', 'connected_name_start', 'connected_mdl_fq_name']}
  },
  bulk_connect (config, {mdl_bldg_new, mdl_room_new, name_stop_new, name_start_new, mdl_fq_name_new, include_all_ports_new = false, type_group_is_fix_new, connected_mdl_bldg_new, connected_mdl_room_new, connected_name_start_new, connected_mdl_fq_name_new}) {
    const params = { 'new': { 'mdl_bldg': mdl_bldg_new, 'mdl_room': mdl_room_new, 'name_stop': name_stop_new, 'name_start': name_start_new, 'mdl_fq_name': mdl_fq_name_new, 'include_all_ports': include_all_ports_new, 'type_group_is_fix': type_group_is_fix_new, 'connected_mdl_bldg': connected_mdl_bldg_new, 'connected_mdl_room': connected_mdl_room_new, 'connected_name_start': connected_name_start_new, 'connected_mdl_fq_name': connected_mdl_fq_name_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/p_port/bulk_connect`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  bulk_disconnectParamsList () {
    return {'old': [ 'mdl_bldg', 'mdl_room', 'name_stop', 'name_start', 'mdl_fq_name', 'include_all_ports', 'type_group_is_fix', 'include_all_submdls', 'do_delete_patch_cable'], 'new': [ ]}
  },
  bulk_disconnect (config, {mdl_bldg_old, mdl_room_old, name_stop_old, name_start_old, mdl_fq_name_old, include_all_ports_old = false, type_group_is_fix_old, include_all_submdls_old = false, do_delete_patch_cable_old = false}) {
    const params = { 'new': { }, 'old': { 'mdl_bldg': mdl_bldg_old, 'mdl_room': mdl_room_old, 'name_stop': name_stop_old, 'name_start': name_start_old, 'mdl_fq_name': mdl_fq_name_old, 'include_all_ports': include_all_ports_old, 'type_group_is_fix': type_group_is_fix_old, 'include_all_submdls': include_all_submdls_old, 'do_delete_patch_cable': do_delete_patch_cable_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/p_port/bulk_disconnect`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  bulk_move_connectionParamsList () {
    return {'old': [ 'mdl_bldg', 'mdl_room', 'name_stop', 'name_start', 'mdl_fq_name', 'type_group_is_fix', 'do_copy_pp_description'], 'new': [ 'connected_mdl_bldg', 'connected_mdl_room', 'connected_name_start', 'connected_mdl_fq_name']}
  },
  bulk_move_connection (config, {mdl_bldg_old, mdl_room_old, name_stop_old, name_start_old, mdl_fq_name_old, type_group_is_fix_old = false, connected_mdl_bldg_new, connected_mdl_room_new, connected_name_start_new, connected_mdl_fq_name_new, do_copy_pp_description_old = false}) {
    const params = { 'new': { 'connected_mdl_bldg': connected_mdl_bldg_new, 'connected_mdl_room': connected_mdl_room_new, 'connected_name_start': connected_name_start_new, 'connected_mdl_fq_name': connected_mdl_fq_name_new}, 'old': { 'mdl_bldg': mdl_bldg_old, 'mdl_room': mdl_room_old, 'name_stop': name_stop_old, 'name_start': name_start_old, 'mdl_fq_name': mdl_fq_name_old, 'type_group_is_fix': type_group_is_fix_old, 'do_copy_pp_description': do_copy_pp_description_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/p_port/bulk_move_connection`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  connectParamsList () {
    return {'old': [ ], 'new': [ 'name', 'mdl_bldg', 'mdl_room', 'mdl_fq_name', 'connected_name', 'patch_cable_name', 'type_group_is_fix', 'connected_mdl_bldg', 'connected_mdl_room', 'connected_mdl_fq_name']}
  },
  connect (config, {name_new, mdl_bldg_new, mdl_room_new, mdl_fq_name_new, connected_name_new, patch_cable_name_new, type_group_is_fix_new, connected_mdl_bldg_new, connected_mdl_room_new, connected_mdl_fq_name_new}) {
    const params = { 'new': { 'name': name_new, 'mdl_bldg': mdl_bldg_new, 'mdl_room': mdl_room_new, 'mdl_fq_name': mdl_fq_name_new, 'connected_name': connected_name_new, 'patch_cable_name': patch_cable_name_new, 'type_group_is_fix': type_group_is_fix_new, 'connected_mdl_bldg': connected_mdl_bldg_new, 'connected_mdl_room': connected_mdl_room_new, 'connected_mdl_fq_name': connected_mdl_fq_name_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/p_port/connect`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  disconnectParamsList () {
    return {'old': [ 'name', 'mdl_bldg', 'mdl_room', 'mdl_fq_name', 'type_group_is_fix', 'do_delete_patch_cable'], 'new': [ ]}
  },
  disconnect (config, {name_old, mdl_bldg_old, mdl_room_old, mdl_fq_name_old, type_group_is_fix_old = false, do_delete_patch_cable_old = false}) {
    const params = { 'new': { }, 'old': { 'name': name_old, 'mdl_bldg': mdl_bldg_old, 'mdl_room': mdl_room_old, 'mdl_fq_name': mdl_fq_name_old, 'type_group_is_fix': type_group_is_fix_old, 'do_delete_patch_cable': do_delete_patch_cable_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/p_port/disconnect`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { gpk, name, type, speed, is_nnl, gpk_list, protocol, name_list, type_group, fetch_limit, name_regexp, fetch_offset, is_connected, is_edge_node, mdl_bldg_list, mdl_room_list, mdl_fq_name_list, filter_params_dict, sorting_params_list, is_internal_connected }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'name': name, 'type': type, 'speed': speed, 'is_nnl': is_nnl, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'protocol': protocol, 'name_list': (name_list === null) ? null : JSON.stringify(name_list), 'type_group': type_group, 'fetch_limit': fetch_limit, 'name_regexp': name_regexp, 'fetch_offset': fetch_offset, 'is_connected': is_connected, 'is_edge_node': is_edge_node, 'mdl_bldg_list': (mdl_bldg_list === null) ? null : JSON.stringify(mdl_bldg_list), 'mdl_room_list': (mdl_room_list === null) ? null : JSON.stringify(mdl_room_list), 'mdl_fq_name_list': (mdl_fq_name_list === null) ? null : JSON.stringify(mdl_fq_name_list), 'filter_params_dict': filter_params_dict, 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list), 'is_internal_connected': is_internal_connected})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/p_port/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
  updateParamsList () {
    return {'old': [ 'name', 'mdl_bldg', 'mdl_room', 'mdl_fq_name'], 'new': [ 'description', 'is_defective']}
  },
  update (config, {name_old, mdl_bldg_old, mdl_room_old, description_new, mdl_fq_name_old, is_defective_new = false}) {
    const params = { 'new': { 'description': description_new, 'is_defective': is_defective_new}, 'old': { 'name': name_old, 'mdl_bldg': mdl_bldg_old, 'mdl_room': mdl_room_old, 'mdl_fq_name': mdl_fq_name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/p_port/update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
}
